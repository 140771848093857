/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Button in toast that will process a single participants access request
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Header.Toasts.Buttons.HandleSingleDeviceRequestButtonGroup
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useContext } from "react";
import { useParticipantName } from "~/components/Participants/hooks/useParticipantName";
import ActionButton from "~/components/Utilities/ActionButton";
import { useStrings } from "~/hooks";
import { useSendModeratorAction } from "~/hooks/messaging";
import { alertActions, moderationActions, useModerationState } from "~/state";
import { IParticipantRequest } from "~/state/room";
import { IDynamicMenuData } from "~/state/ui";
import { IModeratorPayload } from "~/types";
import { VideoContext } from "~/web-core/components";
import OpenMenuButton from "./OpenMenuButton";

enum TokenNames {
	grantAccess = "GrantAccess",
	deny = "Deny",
}

interface IToastProps {
	toastId: string;
	identity: string;
}

/**
 * The HandleSingleDeviceRequestButtonGroup component
 * @param props The props ;)
 */
const HandleSingleDeviceRequestButtonGroup: FC<IToastProps> = (props) => {
	const { toastId, identity } = props;
	const { session } = useContext(VideoContext);
	const strings = useStrings("Toasts", Object.values(TokenNames));
	const sendModeratorMessage = useSendModeratorAction();
	const dispatch = useDispatch();
	const requestInfo = useModerationState(
		(selectors) => selectors.getParticipantRequest(identity),
		[identity],
	);

	const requestCount = getRequestCount(requestInfo);
	const participant = session?.getRemoteParticipant(identity) ?? null;
	const participantName = useParticipantName(participant) ?? "";
	const menuToOpen = participant ? "participantOptions" : "participants";
	const participantMenuData: IDynamicMenuData | undefined = participant
		? { participant: participant, title: participantName }
		: undefined;

	const allowSingleRequest = useCallback(() => {
		dispatch(alertActions.dismissToastAlert(toastId));
		const moderatorParams: IModeratorPayload = {
			audioLock: undefined,
			videoLock: undefined,
			screenShareLock: undefined,
		};
		if (requestInfo.audio) {
			moderatorParams.audioLock = false;
		}
		if (requestInfo.video) {
			moderatorParams.videoLock = false;
		}
		if (requestInfo.screenShare) {
			moderatorParams.screenShareLock = false;
		}
		/** Send Moderator Message to update remote user's state */
		sendModeratorMessage(moderatorParams, identity, true);
	}, [
		dispatch,
		identity,
		requestInfo.audio,
		requestInfo.screenShare,
		requestInfo.video,
		sendModeratorMessage,
		toastId,
	]);

	const denyRequest = useCallback(() => {
		// Clear the state so we no longer receive notifications for this request
		if (requestInfo) {
			dispatch(moderationActions.clearParticipantRequest(requestInfo.identity));
		}

		dispatch(alertActions.dismissToastAlert(toastId));
	}, [dispatch, toastId, requestInfo]);

	return (
		<>
			{/** For multiple requests, instead open the user's moderation menu */}
			{requestCount <= 1 ? (
				<ActionButton
					tone="positive"
					priority="primary"
					onClick={allowSingleRequest}
					text={strings[TokenNames.grantAccess]}
				/>
			) : (
				<OpenMenuButton menu={menuToOpen} menuData={participantMenuData} />
			)}
			<ActionButton
				tone="negative"
				priority="primary"
				onClick={denyRequest}
				text={strings[TokenNames.deny]}
			/>
		</>
	);
};

function getRequestCount(requestInfo: IParticipantRequest): number {
	let requestCount = 0;
	if (requestInfo.audio) {
		requestCount++;
	}
	if (requestInfo.video) {
		requestCount++;
	}
	if (requestInfo.screenShare) {
		requestCount++;
	}
	return requestCount;
}

HandleSingleDeviceRequestButtonGroup.displayName = "HandleSingleDeviceRequestButtonGroup";

export default React.memo(HandleSingleDeviceRequestButtonGroup);
