/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Button component with card styling
 * @author Hanita Haller
 * @module Epic.VideoApp.Components.Utilities.CardButton
 */

import React, { FC } from "react";
import { resolveClassName } from "~/utils/className";
import { noOp } from "~/utils/general";
import styles from "./CardButton.module.scss";

/**
 * Props for CardButton Component
 */
interface IProps {
	/** Function to be called when the card button is clicked */
	onClick: () => void;
	/** The tone of the button */
	tone: "positive" | "neutral" | "negative";

	active?: boolean;
}

/**
 * The CardButton component
 *
 * @param props The props :D
 */
const CardButton: FC<IProps> = (props) => {
	const { onClick, tone, active = false, children } = props;

	const classNames: Record<string, boolean> = {
		cardButton: true,
		active,
		[tone]: true,
	};
	const buttonClassName = resolveClassName(styles, classNames);

	return (
		<button type="button" onClick={onClick} className={buttonClassName} onTouchStart={noOp}>
			<div tabIndex={-1}>{children}</div>
		</button>
	);
};

CardButton.displayName = "CardButton";

export default CardButton;
