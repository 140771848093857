/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file The selfie preview in the hardware test
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.InlineVideoPreview
 */

import React, { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocalAudioToggle, useStrings, useWindowSize } from "~/hooks";
import useVideoStreamDimensions from "~/hooks/trackUtilities/useVideoStreamDimensions";
import DisabledCamera from "~/icons/disabledCamera";
import { ScreenWidthInflectionPoint, Timeout } from "~/types";
import { stringFormat } from "~/utils/strings";
import { isTrackFrontFacing } from "~/utils/video";
import { VideoContext } from "~/web-core/components/VideoSessionProvider";
import VideoStream from "~/web-core/components/VideoStream";
import { useAudioLevel } from "~/web-core/hooks/useAudioLevel";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useMediaTrack } from "~/web-core/hooks/useMediaTrack";
import VolumeIndicator from "../HardwareSetup/Devices/VolumeIndicator";
import styles from "./InlineVideoPreview.module.scss";

enum TokenNames {
	noCamera = "NoCamera",
	frontCamera = "FrontCamera",
	cameraDetected = "CameraDetected",
	unnamedCameraDetected = "UnnamedCameraDetected",
	audioIndicatorLabel = "AudioIndicatorLabel",
	recentAudio = "RecentAudio",
	noRecentAudio = "NoRecentAudio",
}

/** Local Constants */
const VOLUME_LEVEL_DETECTION_THRESHOLD = 1; // 1/10 volume
const VOLUME_DETECTION_RECENT_TIMEOUT = 5000; // 5 seconds

export enum InlineVideoPreviewTestIds {
	self = "InlineVideoPreview",
}

/**
 * The InlineVideoPreview component. This is styled to be placed inline, e.g. in the hardware test
 */
const InlineVideoPreview: FC = () => {
	const { stream } = useContext(VideoContext);
	const isVideoEnabled = useIsStreamEnabled("video", stream);
	const [isAudioEnabled] = useLocalAudioToggle();
	const volumeLevel = useAudioLevel(stream);
	const { width: viewportWidth } = useWindowSize();
	// Default to 16:9 aspect ratio (here ratio is between height:width instead of standard width:height to make styling)
	const [aspectRatio, setAspectRatio] = useState((9 / 16) * 100); // Multiply by 100 to express ratio as percentage

	const dimensions = useVideoStreamDimensions(stream);

	const strings = useStrings("InlineVideoPreview", Object.values(TokenNames));
	const mediaStreamTrack = useMediaTrack(stream, "video");

	const videoStreamLabel = useMemo(() => {
		const isFrontFacing = mediaStreamTrack && isTrackFrontFacing(mediaStreamTrack);
		const deviceLabel = stream?.getDeviceName("video");
		let videoStreamLabel;
		if (isVideoEnabled) {
			videoStreamLabel = deviceLabel
				? stringFormat(
						isFrontFacing ? strings[TokenNames.frontCamera] : strings[TokenNames.cameraDetected],
						deviceLabel,
				  )
				: strings[TokenNames.unnamedCameraDetected];
		} else {
			videoStreamLabel = strings[TokenNames.noCamera];
		}
		return videoStreamLabel;
	}, [mediaStreamTrack, stream, isVideoEnabled, strings]);

	const width = dimensions?.width;
	const height = dimensions?.height;
	if (!!width && !!height && aspectRatio !== 100 * (height / width)) {
		setAspectRatio(100 * (height / width));
	}

	const videoPreviewStyle = useMemo(() => {
		let previewStyle: React.CSSProperties = {};

		if (viewportWidth < ScreenWidthInflectionPoint && aspectRatio > 100) {
			// Calculate the % height that's required on each side to make the video preview at largest a square
			// To find the required margin to make the video preview a square, assume a 1x1 square and subtract height/width from the square's width
			let borderWidthFraction = (1 - 100 / aspectRatio) / 2;
			borderWidthFraction += 0.1; // Grow border by 10% to make the video preview even shorter
			previewStyle = { margin: `0 ${100 * borderWidthFraction}%` };
		}
		return previewStyle;
	}, [aspectRatio, viewportWidth]);

	const [noRecentVolume, setNoRecentVolume] = useState<boolean>(true);
	const timeoutRef = useRef<Timeout>();
	const isEnabled = useIsStreamEnabled("audio", stream);

	useEffect(() => {
		if (volumeLevel >= VOLUME_LEVEL_DETECTION_THRESHOLD) {
			setNoRecentVolume(false);
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
				timeoutRef.current = undefined;
			}
		}

		if (volumeLevel < VOLUME_LEVEL_DETECTION_THRESHOLD && isEnabled) {
			if (!timeoutRef.current) {
				timeoutRef.current = setTimeout(() => {
					setNoRecentVolume(true);
				}, VOLUME_DETECTION_RECENT_TIMEOUT);
			}
		}
	}, [isEnabled, volumeLevel]);

	const volumeIndicatorAriaLabel = noRecentVolume
		? strings[TokenNames.noRecentAudio]
		: strings[TokenNames.recentAudio];

	return (
		<div
			className={styles["videoPreview"]}
			style={videoPreviewStyle}
			data-testid={InlineVideoPreviewTestIds.self}
		>
			<div
				// Allowing focus for this non-interactive element per recommendation of a11y expert during AX eval
				// See https://emc2summary/GetSummaryReport.ashx/TRACK/ZDQ/40818418
				// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
				tabIndex={0}
				className={styles["disabledPlaceholder"]}
				style={{ paddingTop: `${aspectRatio}%` }}
				aria-label={videoStreamLabel}
				role="img"
			>
				{isVideoEnabled && stream ? (
					<VideoStream
						className={styles["videoFeed"]}
						stream={stream}
						isLocal
						disablePictureInPicture
					/>
				) : (
					<DisabledCamera className={styles["disabledIcon"]} />
				)}
			</div>

			{isAudioEnabled && (
				<div
					// See https://emc2summary/GetSummaryReport.ashx/TRACK/ZDQ/40817990
					// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
					tabIndex={0}
					aria-label={volumeIndicatorAriaLabel}
					className={styles["audioPreview"]}
					role="img"
				>
					<VolumeIndicator volumeLevel={volumeLevel} isTrackEnabled />
				</div>
			)}
		</div>
	);
};

InlineVideoPreview.displayName = "InlineVideoPreview";

export default InlineVideoPreview;
