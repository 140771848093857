/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file A hidden div used to read screen reader only accessibility alerts
 * @author Jonathon Moore
 * @module Epic.VideoApp.Components.Alerts.AccessibilityAlert
 */

import React, { FC, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useStrings } from "~/hooks";
import { alertActions, useAlertState } from "~/state/alerts";
import { stringFormat } from "~/utils/strings";
import styles from "./Alerts.module.scss";

export enum AccessibilityAlertTestingIds {
	self = "AccessibilityAlert",
}

enum TokenNames {
	backgroundSet = "BackgroundSet",
	backgroundCleared = "BackgroundCleared",
}

const AccessibilityAlert: FC = () => {
	const alertBackground = useAlertState((selectors) => selectors.getAccessibilityAlert(), []);

	const backgroundName = useAlertState((selectors) => selectors.getBackgroundName(), []);

	const alertBackgroundCleared = useAlertState((selectors) => selectors.getBackgroundCleared(), []);

	const activeString = useRef<string>();

	const dispatch = useDispatch();

	const strings = useStrings("AccessibilityAlert", Object.values(TokenNames));

	useEffect(() => {
		if ((alertBackground && backgroundName) || alertBackgroundCleared) {
			if (alertBackgroundCleared) {
				activeString.current = strings[TokenNames.backgroundCleared];
			} else {
				activeString.current = stringFormat(strings[TokenNames.backgroundSet], backgroundName);
			}

			//Clearing out the string once it's been read out.
			setTimeout(() => {
				if (alertBackground) {
					dispatch(alertActions.setAccessibilityAlert(false));
				}
				if (alertBackgroundCleared) {
					dispatch(alertActions.setBackgroundCleared(false));
				}
				dispatch(alertActions.setBackgroundName(""));
				activeString.current = "";
			}, 50);
		}
	}, [alertBackground, dispatch, strings, backgroundName, alertBackgroundCleared]);

	return (
		<div
			className={styles["screenReaderOnly"]}
			aria-live="assertive"
			aria-relevant="text"
			data-testid={AccessibilityAlertTestingIds.self}
		>
			{activeString.current}
		</div>
	);
};

AccessibilityAlert.displayName = "AccessibilityAlert";

export default AccessibilityAlert;
