/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file shared core state
 * @author Steven Anderson
 * @module Epic.VideoApp.State.Core
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";

/// TYPES ///

export interface ICoreState {
	readonly clientVersion: number | null;
}

/// INIT ///

export function getInitialState(): ICoreState {
	return {
		clientVersion: 99.99,
	};
}

/// REDUCERS ///
export function clearCoreState(): ICoreState {
	return getInitialState();
}

export function setClientVersion(state: ICoreState, newClientVersion: number | null): ICoreState {
	return { ...state, clientVersion: newClientVersion };
}

/// SELECTORS ///

export function getClientVersion(state: ICoreState): number | null {
	return state.clientVersion;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: { setClientVersion, clearCoreState },
	selectors: { getClientVersion },
});

store.addSharedState(builtState.sharedState, "core");

export const { actionCreators: coreActions, useSharedState: useCoreState, sharedState: state } = builtState;
