/**
 * @copyright Copyright 2021 - 2024 Epic Systems Corporation
 * @file LanguageSelector component
 * @author Razi Rais
 * @module Epic.VideoApp.Components.LanguageSelector.LanguageSelector
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useMemo } from "react";
import { alertActions, uiActions, useSettingsState } from "~/state";
import { settingsActions } from "~/state/settings";
import { I18n } from "~/utils/i18n";
import LanguageOption from "./LanguageOption";

/**
 * The LanguageSelector component
 */
const LanguageSelector: FC = () => {
	const supportedLocales = useMemo(() => I18n.getLocales(), []);
	const locale = useSettingsState((selectors) => selectors.getLocale(), []);
	const dispatch = useDispatch();

	const onLocaleSelected = useCallback(
		async (newLocale: string) => {
			dispatch(settingsActions.setLoadingLocale(newLocale));

			// Close menu after apply button is clicked
			dispatch(uiActions.toggleVisibleMenu({ menu: null }));

			try {
				await I18n.setLocale(newLocale, dispatch);
			} catch {
				dispatch(settingsActions.setLoadingLocale(null));
			}
			dispatch(alertActions.clearToasts("language-loading"));
		},
		[dispatch],
	);

	return (
		<div>
			{supportedLocales.map((localeOption) => (
				<LanguageOption
					key={localeOption.localeCode}
					locale={localeOption}
					isSelected={locale === localeOption.localeCode}
					onSelected={onLocaleSelected}
				/>
			))}
		</div>
	);
};

LanguageSelector.displayName = "LanguageSelector";

export default LanguageSelector;
