/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file A wrapper to collapse/expand content smoothly
 * @author Tara Feldstein
 * @module Epic.VideoApp.Components.Utilities.Collapse
 */
import React, { FC, useEffect, useRef, useState } from "react";
import { resolveClassName } from "~/utils/className";
import styles from "./Collapse.module.scss";

interface IProps {
	/** Whether or not the collapsible section is currently collapsed */
	collapsed: boolean;
	/** Class name for the inner div of the collapse section */
	innerClassName?: string;
	/** Callback for when the collapse section has finished animating */
	onAnimationFinished?: (collapsed: boolean) => void;
	/** HTML ID to apply to the outer div */
	id?: string;
}

export enum CollapseTestIds {
	self = "Collapse",
}

/**
 * Collapsing section
 *
 * Collapses/expands its children smoothly by transitioning the height in css
 */
const Collapse: FC<IProps> = (props) => {
	const { collapsed, innerClassName, onAnimationFinished, id, children } = props;
	const [isFullyExpanded, setFullyExpanded] = useState(!collapsed);

	const inner = useRef<HTMLDivElement>(null);
	const innerHeight = (inner.current?.clientHeight || 202) - 2; // Remove 2px because of some trickery with padding to account for the margins in the children

	// Update isFullyExpanded so we can let the contents change in size
	useEffect(() => {
		setFullyExpanded(false);

		const handle = setTimeout(() => {
			if (!collapsed) {
				setFullyExpanded(true);
			}
			onAnimationFinished?.(collapsed);
		}, 400); // The expand/collapse animation lasts 400ms / 0.4s

		return () => clearTimeout(handle);
	}, [collapsed, onAnimationFinished]);

	const outerClass = resolveClassName(styles, { outer: true, collapsed });
	const style: React.CSSProperties = {
		maxHeight: collapsed ? (isFullyExpanded ? innerHeight : 0) : isFullyExpanded ? "none" : innerHeight,
		visibility: collapsed ? "hidden" : "visible",
	};

	const innerClass = resolveClassName(styles, { inner: true }, innerClassName);

	return (
		<div
			style={style}
			className={outerClass}
			aria-hidden={collapsed}
			id={id}
			data-testid={CollapseTestIds.self}
		>
			<div ref={inner} className={innerClass}>
				{children}
			</div>
		</div>
	);
};

Collapse.displayName = "Collapse";

export default Collapse;
