/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file general utility functions
 * @author Colin Walters
 * @module Epic.VideoApp.Utils.General
 */

import { getEndpointUrl } from "~/app/routes";
import { ToggleState, VideoMargin } from "~/types";
import { BackgroundResourceType } from "~/types/backgrounds";

const DEV_SERVER = "https://localhost:44334";

/**
 * Get whether or not the application is running in a development environment
 * @returns true if running in a development environment, false otherwise
 */
export function isDevEnvironment(): boolean {
	return process.env.NODE_ENV === "development";
}

/**
 * Get the URL to an API on our application's web server, taking into account dev environments
 * @param path path to make the link to
 * @returns URL to the specified path
 */
export function makeLink(path: string): string {
	let basePath = window.ServerUrl;
	if (!basePath) {
		basePath = isDevEnvironment() ? DEV_SERVER : window.ServerUrl;
	}
	basePath = basePath ?? "/";
	if (!basePath.endsWith("/")) {
		basePath += "/";
	}
	path = getEndpointUrl(path); // Use appropriate path prefix to get endpoint URL.
	const pathName = path.replace(/^\/+/g, ""); // remove leading /
	return basePath + pathName;
}

/**
 * Get the URL to a resource on our application's web server
 * @param type the type of resource being loaded
 * @param file the resource file name to get a link to
 * @returns URL to the specified resource
 */
export function makeResourceLink(
	type: "AudioFile" | "Processor" | BackgroundResourceType,
	file: string,
): string {
	return makeLink(`Resource/${type}/${file}`);
}

/**
 * Get a pseudo random ID from combination of timestamp and random number
 * @returns pseudo random ID
 */
export function getPseudoRandomId(): string {
	// date time in milliseconds followed by a random number all converted to base 16
	return Date.now().toString(16) + Math.random().toString(16).substring(2);
}

/**
 * No op
 *
 * iOS Safari doesn't display :active CSS styling unless touch event listeners are present (see https://bit.ly/3kOYu1v),
 * so we attach this noop to various buttons. MyChart applies a similar workaround that is bound to elements with class="button" here:
 * https://codesearch/default.aspx?view=search/client#name=PatientEngagement%5CMyChartWebServer%5CWeb%5CScripts%5CUI_Framework%5CCore%5CCommon.js
 */
export function noOp(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

/**
 * Determine if the boolean value matches the ToggleState passed in
 * @param bool boolean value to check
 * @param state toggle state to check
 * @returns true if bool "matches" toggle, (true and "on") or (false and "off"), false otherwise
 */
export function boolMatchesToggleState(bool: boolean, state?: ToggleState): boolean {
	return (bool && state === "on") || (!bool && state === "off");
}

/**
 * Filter a list of values to a list of its unique entries
 * @param array list to filter
 * @returns array filtered down to its unique entries
 */
export function getUniqueValues<T>(array: T[]): T[] {
	return array.filter((val, index) => array.indexOf(val) === index);
}

/**
 * Convert the string into an arraybuffer (charCode byte array)
 * that can be directly passed from this request body to another resource
 *
 * @param input String to convert to byte values
 * @returns An ArrayBuffer representing the data in the
 */
export function stringToBuffer(input: string): ArrayBuffer {
	const length = input.length;
	const bytes = new Uint8Array(length);
	for (let i = 0; i < length; i++) {
		bytes[i] = input.charCodeAt(i);
	}
	return bytes.buffer;
}

/**
 * Try to focus a DOM element
 * @param selectors array of HTML selectors used to find an element to focus, the first selector that finds an element will be used
 * @returns true if an element is focused, false otherwise
 */
export function focusElement(selectors: string | string[]): boolean {
	selectors = Array.isArray(selectors) ? selectors : [selectors];

	// find the first element matching one of the selectors
	let elementToFocus: Element | null = null;
	for (let i = 0; i < selectors.length && !elementToFocus; ++i) {
		elementToFocus = document.querySelector(selectors[i]);
	}

	if (!elementToFocus) {
		return false;
	}

	const htmlElement = elementToFocus as HTMLElement;
	htmlElement.focus();
	return true;
}

/**
 *
 * Helper function to determine the effective width or height of a video element
 *
 * @param dimension - The height or width to modify with the expected margin of the video
 * @returns The effective size of the video (height or width) + double the margin
 */
export function getVideoSizeWithMargin(dimension: number): number {
	return dimension + VideoMargin * 2;
}
