/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Warning banner to inform the user that they are on an iOS version that may have poor audio with headphones
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareTest.Banners.HeadphoneAudioWarning
 */

import React, { FC } from "react";
import { useStrings } from "~/hooks";
import BubbleBanner from "../../Utilities/BubbleBanner";

enum TokenNames {
	title = "Title",
	message = "Message",
	upgradeMessage = "UpgradeMessage",
}
interface IProps {
	inline?: boolean;
}

/**
 * The HeadphoneAudioWarning component
 */
const HeadphoneAudioWarning: FC<IProps> = (props) => {
	const { inline = false } = props;
	const strings = useStrings("HeadphoneAudioWarning", Object.values(TokenNames));

	return (
		<BubbleBanner
			type="warning"
			title={strings[TokenNames.title]}
			message={strings[TokenNames.upgradeMessage]}
			inline={inline}
		/>
	);
};

HeadphoneAudioWarning.displayName = "HeadphoneAudioWarning";

export default HeadphoneAudioWarning;
