/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Home page component
 * @author Erv Walter
 * @module Epic.VideoApp.Features.DebugDevices.DebugDevices
 */

import { RouteComponentProps } from "@reach/router";
import React, { FC } from "react";
import DevicePermissionsDebugger from "~/components/Utilities/DevicePermissionsDebugger";
import { useDocumentTitle } from "~/hooks";

/**
 * The Home page component
 * @param _props The props ;)
 */
const Home: FC<RouteComponentProps> = (_props) => {
	useDocumentTitle("Debugging page for devices");

	return <DevicePermissionsDebugger />;
};

Home.displayName = "Home";

export default Home;
