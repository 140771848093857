/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access */
/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Debugger info for device permissions
 * @author Tara Feldstein
 * @module Epic.VideoApp.Components.Utilities.DevicePermissionsDebugger
 */

import React, { FC, useCallback, useEffect, useState } from "react";
import SimpleCard from "~/components/Cards/SimpleCard";

type LoadingState =
	| "loading"
	| "noMediaDevices"
	| "loadedDevices"
	| "requestingPerms"
	| "gotPerms"
	| "errLoadingDevices"
	| "errGettingPerms";

interface IDevicesInfo {
	videoTotal: number;
	audioInTotal: number;
	audioOutTotal: number;

	videoAvailable: number;
	audioInAvailable: number;
	audioOutAvailable: number;
}

/**
 * The DevicePermissionsDebugger component
 * @param _props The props ;)
 */
const DevicePermissionsDebugger: FC = () => {
	const [currentState, setCurrentState] = useState<LoadingState>("loading");

	const [info, setInfo] = useState<IDevicesInfo | null>(null);

	const [error, setError] = useState<any>("");

	const [permReqStart, setPermReqStart] = useState<number>(0);
	const [permReqEnd, setPermReqEnd] = useState<number>(0);
	const permReqTime = permReqEnd - permReqStart;

	useEffect(() => {
		if (!navigator.mediaDevices) {
			setCurrentState("noMediaDevices");
			return;
		}
		// eslint-disable-next-line compat/compat
		navigator.mediaDevices
			.enumerateDevices()
			.then((devices: MediaDeviceInfo[]) => {
				const audioIn = devices.filter((info) => info.kind === "audioinput");
				const audioOut = devices.filter((info) => info.kind === "audiooutput");
				const videoIn = devices.filter((info) => info.kind === "videoinput");
				const audioInAvailable = devices.filter(
					(info) => info.kind === "audioinput" && info.label !== "",
				);
				const audioOutAvailable = devices.filter(
					(info) => info.kind === "audiooutput" && info.label !== "",
				);
				const videoInAvailable = devices.filter(
					(info) => info.kind === "videoinput" && info.label !== "",
				);
				const info: IDevicesInfo = {
					videoTotal: videoIn.length,
					audioInTotal: audioIn.length,
					audioOutTotal: audioOut.length,

					videoAvailable: videoInAvailable.length,
					audioInAvailable: audioInAvailable.length,
					audioOutAvailable: audioOutAvailable.length,
				};

				setInfo(info);
				setCurrentState("loadedDevices");
			})
			.catch((reason) => {
				setError(reason);
				setCurrentState("errLoadingDevices");
			});
	}, []);

	let content: string | React.ReactElement = "";
	switch (currentState) {
		case "noMediaDevices":
			content =
				"Browser doesn't support acquiring devices - this would be caught by our supported browser checks";
			break;
		case "loadedDevices":
			content = "Loaded devices, but somehow didn't save info from loading devices";
			if (info) {
				content = parseDeviceInfo(info);
			}
			break;
		case "errLoadingDevices":
			content = parseError("Somehow got an error calling enumerateDevices: ", error);
			break;
		case "errGettingPerms":
			content = parsePermissionError(error, permReqTime);
			break;
		case "gotPerms":
			content = `Got permissions in ${permReqTime}ms - Looks like a manual approval`;
			if (permReqTime < 2000) {
				content = "Permissions acquired quickly - Looks like an automatic approval";
			}

			break;
		case "loading":
		default:
			content = "Loading...";
	}

	const showPermRequestButton = currentState === "loadedDevices";

	const requestPerms = useCallback(() => {
		setPermReqStart(Date.now());

		const constraints = { video: true, audio: true };
		// eslint-disable-next-line compat/compat
		navigator.mediaDevices
			.getUserMedia(constraints)
			.then(() => {
				setCurrentState("gotPerms");
			})
			.catch((reason) => {
				const err = {
					code: reason.code,
					message: reason.message,
					name: reason.name,
					raw: reason,
				};
				setError(err);
				setCurrentState("errGettingPerms");
			})
			.finally(() => {
				setPermReqEnd(Date.now());
			});
	}, []);

	return (
		<SimpleCard>
			<div style={{ padding: "20px 40px" }}>
				{content}
				{showPermRequestButton && (
					<>
						<br />
						<button onClick={requestPerms}>Request Permissions</button>
					</>
				)}
			</div>
		</SimpleCard>
	);
};

DevicePermissionsDebugger.displayName = "DevicePermissionsDebugger";

export default DevicePermissionsDebugger;

function parseDeviceInfo(info: IDevicesInfo): React.ReactElement {
	const visibleStr = `${info.videoTotal} video, ${info.audioInTotal} audio input, ${info.audioOutTotal} audio output`;
	const allowedStr = `${info.videoAvailable} video, ${info.audioInAvailable} audio input, ${info.audioOutAvailable} audio output`;

	type DevType = "Camera" | "Microphone";
	const allowed: DevType[] = [];
	const present: DevType[] = [];
	const missing: DevType[] = [];
	if (info.videoAvailable > 0) {
		allowed.push("Camera");
	} else if (info.videoTotal > 0) {
		present.push("Camera");
	} else {
		missing.push("Camera");
	}
	if (info.audioInAvailable > 0) {
		allowed.push("Microphone");
	} else if (info.audioInTotal > 0) {
		present.push("Microphone");
	} else {
		missing.push("Microphone");
	}

	let concStr = "";
	if (allowed.length > 0) {
		concStr += allowed[0] + " permissions already allowed. ";
		if (allowed.length > 1) {
			concStr =
				"All camera/mic permissions are granted, the permission request should succeed instantly and without requiring any click";
		}
	}
	if (present.length > 0) {
		concStr += present.length > 1 ? "Camera and Microphone" : present[0];
		concStr += " devices present on device, need to be requested. ";
	}
	if (missing.length > 0) {
		concStr += missing.length > 1 ? "Camera and Microphone" : missing[0];
		concStr += " devices not present on the device, permissions request will always fail. ";
	}

	return (
		<div>
			<div>
				<b>Visible devices:</b> <div>{visibleStr}</div>
			</div>
			<div>
				<b>Allowed devices:</b> <div>{allowedStr}</div>
			</div>
			<div>
				<br />
				<b>Conclusion:</b> <div>{concStr}</div>
			</div>
		</div>
	);
}

function errMatches(error: IErrorData, expected: IErrorData): boolean {
	return error.message === expected.message;
}

function errsMatch(error: IErrorData, expected: IErrorData[]): boolean {
	return expected.map((e) => errMatches(error, e)).includes(true);
}

function parsePermissionError(error: IErrorData, duration: number): React.ReactElement {
	const autoErrorTime = 2000;

	let parsedErr =
		"Tara hasn't written a case for this error - it doesn't match one of the cases we currently handle";
	let extraTxt = "";
	let suggestion = "None - More information is needed about this error case";

	// General acquisition
	if (errMatches(error, errNotFound)) {
		parsedErr = "System could not find one of the requested device types. ";
		extraTxt = "This should have been expected before requesting devices.";
		suggestion = "Switch to a different device, or plug in a webcam/mic";
	} else if (errsMatch(error, [errIOFail])) {
		parsedErr = "Unhandled general issue - Tara didn't encounter this in web testing";
		extraTxt = "Some device probably was unavailable?";
	}
	// Microphone
	else if (errsMatch(error, [errMicNoStart, errMicFailStart, errMicNoAllocate])) {
		parsedErr = "Unhandled mic issue - Tara didn't encounter this in web testing";
		extraTxt = "The microphone is probably in use or disabled in the system somehow";
		suggestion = "Close any other apps that might be using your microphone";
	}
	// Camera
	else if (errMatches(error, errCamNoStart)) {
		parsedErr =
			"Error connecting to the camera device, probably disabled in system settings or already in use";
		suggestion = "Close any other apps that might be using your camera";
	} else if (errMatches(error, errCamNoAllocate)) {
		parsedErr =
			"Error connecting to the camera device, probably disabled in system settings or already in use";
		suggestion = "Close any other apps that might be using your camera";
	} else if (errsMatch(error, [errCamFailStart])) {
		parsedErr = "Unhandled camera issue - Tara didn't encounter this in web testing";
		extraTxt = "The camera is probably unavailable though";
	}
	// Permissions
	else if (errMatches(error, errPermDenied)) {
		parsedErr = "Permissions were denied";
		suggestion =
			"Allow permissions with the browser and refresh (maybe browser-specific instructions here)";
		if (duration < autoErrorTime) {
			suggestion = "Stop auto-blocking permissions (browser-specific instructions here)";
		}
	} else if (errMatches(error, errPermDismissed)) {
		parsedErr = "User dismissed the request without approving or denying";
		suggestion = "Refresh the page and allow permissions (or can we give a popup and retry?)";
	} else if (errsMatch(error, [errReqNotAllowedPoss, errReqNotAllowed])) {
		parsedErr = "Permissions were denied";
		suggestion =
			"Allow permissions with the browser and refresh (maybe browser-specific instructions here)";
		if (duration < autoErrorTime) {
			suggestion = "Stop auto-blocking permissions (browser-specific instructions here)";
		}
	}
	// Errors not in EVC
	else if (errMatches(error, errDeniedBySystem)) {
		parsedErr = "Permissions were denied by the system";
		extraTxt = "Most likely this was the microphone being disabled by windows settings";
		suggestion =
			"Since a lot of users don't know how to change windows settings, maybe we suggest using a mobile device here";
	} else if (errMatches(error, errAbort)) {
		parsedErr = "Acquiring the camera was aborted by the browser";
		extraTxt = "This seems to be a Firefox-specific issue with requesting permissions after a refresh";
		suggestion = "Try refreshing? This error doesn't seem consistent";
	} else if (errMatches(error, errObjectNotFound)) {
		parsedErr = "A requested device could not be found";
		extraTxt =
			"This seems to be how Firefox handles it when the only microphone is disabled via the sound control panel";
		suggestion =
			"Since a lot of users don't know how to change windows settings, maybe we suggest using a mobile device here";
	}

	const noSee =
		"I think the user either didn't see the approve/block popup, or clicked an option very quickly";
	const see = "I think the user saw the approve/block popup";
	const sawText = duration < autoErrorTime ? noSee : see;
	return (
		<div>
			<h3>Permission request failed</h3>
			<div>
				<b>Raw error:</b>
				<div>
					{error.name} [{error.code}]
				</div>
				<div>{error.message}</div>
				<div>Request failed in {duration}ms</div>
			</div>
			<div>
				<br />
				<b>Conclusion:</b>
				<div>{sawText}</div>
				<br />
				<div>{parsedErr}</div>
				<div>{extraTxt}</div>
				<br />
				<b>Suggested action/fix:</b>
				<div>{suggestion}</div>
			</div>
		</div>
	);
}

interface IErrorData {
	code: number;
	message: string;
	name: string;
	raw: any;
}

// Generic errors
const errNotFound: IErrorData = {
	code: 8,
	name: "NotFoundError",
	message: "Requested device not found",
	raw: null,
};
const errIOFail: IErrorData = {
	code: 0,
	name: "UnknownName",
	message: "The I/O read operation failed.",
	raw: null,
};

// Microphone
const errMicNoStart: IErrorData = {
	code: 0,
	name: "NotReadableError",
	message: "Could not start audio source",
	raw: null,
};
const errMicFailStart: IErrorData = {
	code: 0,
	name: "UnknownName",
	message: "Failed starting capture of a audio track",
	raw: null,
};
const errMicNoAllocate: IErrorData = {
	code: 0,
	name: "UnknownName",
	message: "Failed to allocate audiosource",
	raw: null,
};

// Camera
const errCamNoStart: IErrorData = {
	code: 0,
	name: "NotReadableError",
	message: "Could not start video source",
	raw: null,
};
const errCamFailStart: IErrorData = {
	code: 0,
	name: "UnknownName",
	message: "Failed starting capture of a video track",
	raw: null,
};
const errCamNoAllocate: IErrorData = {
	code: 0,
	name: "NotReadableError",
	message: "Failed to allocate videosource",
	raw: null,
};

// Permissions
const errPermDenied: IErrorData = {
	code: 0,
	name: "NotAllowedError",
	message: "Permission denied",
	raw: null,
};

const errPermDismissed: IErrorData = {
	code: 0,
	name: "NotAllowedError",
	message: "Permission dismissed",
	raw: null,
};

const errReqNotAllowedPoss: IErrorData = {
	code: 0,
	name: "NotAllowedError",
	message:
		"The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.",
	raw: null,
};

const errReqNotAllowed: IErrorData = {
	code: 0,
	name: "NotAllowedError",
	message: "The request is not allowed by the user agent or the platform in the current context.",
	raw: null,
};

// Not in EVC yet
const errDeniedBySystem: IErrorData = {
	code: 0,
	name: "NotAllowedError",
	message: "Permission denied by system",
	raw: null,
};

const errAbort: IErrorData = {
	code: 20,
	name: "AbortError",
	message: "Starting videoinput failed",
	raw: null,
};

const errObjectNotFound: IErrorData = {
	code: 0,
	name: "NotFoundError",
	message: "The object can not be found here.",
	raw: null,
};

function parseError(title: string, error: IErrorData): React.ReactElement {
	return (
		<div>
			<h3>{title}</h3>
			<div>
				<b>
					{error.name} [{error.code}]
				</b>
				<div>{error.message}</div>
			</div>
		</div>
	);
}
