/**
 * @copyright Copyright 2020-2022 Epic Systems Corporation
 * @file Top level React component for the application
 * @author Erv Walter
 * @module Epic.VideoApp.App.App
 */

import { Redirect, Router } from "@reach/router";
import React, { FC, Fragment } from "react";
import { Provider } from "react-redux";
import BrandingStyleSheet from "~/components/Branding/BrandingStyleSheet";
import StandardLayout from "~/components/StandardLayout/StandardLayout";
import NotFound from "~/features/not-found/NotFound";
import { coreActions } from "~/state";
import { FullScreenContainerId } from "~/types";
import { getCookie } from "~/utils/cookies";
import { embeddedMobileConnectedCheck } from "~/utils/windowGlobals";
import styles from "./App.module.scss";
import "./registerRoutes.codegen";
import { getRegisteredRoutes } from "./routes";
import store from "./store";

/**
 * Top level React component for the application.  Handles setting up the redux store provider, suspense, and routing
 * Note: the routes below are case sensitive, so to try to combat this until the router updates, we've added the toLowerCase and toUpperCase of all routes/redirects
 */
const App: FC = () => {
	// add custom global functions that should be available throughout the app here
	window.IsConnectedToVideoCall = embeddedMobileConnectedCheck;

	//Page load will return cookies for client version and server URL.
	//These are consumed here, and will expire shortly thereafter.
	const clientVersion = getCookie("x-epic-evc-client-version");
	if (clientVersion) {
		coreActions.setClientVersion(+clientVersion);
	}
	//const serverUrl = getCookie("x-epic-evc-server-url");
	const serverUrl = "https://epictelehealth-tst.azurefd.net/telehealth/evc-mr-3315/";
	if (serverUrl) {
		window.ServerUrl = serverUrl; //Since this is used outside of the React framework, it is set on the window object.
	}

	return (
		<React.StrictMode>
			<Provider store={store}>
				<BrandingStyleSheet />
				<Router id={FullScreenContainerId} className={styles["router"]}>
					{getRegisteredRoutes().map((route) => (
						<Fragment key={route.path}>
							{(route.redirectPaths ?? []).map((redirectPath) => [
								<Redirect from={redirectPath} to={route.path} key={redirectPath} />,
								<Redirect
									from={redirectPath.toLowerCase()}
									to={route.path}
									key={redirectPath.toLowerCase()}
								/>,
								<Redirect
									from={redirectPath.toUpperCase()}
									to={route.path}
									key={redirectPath.toUpperCase()}
								/>,
							])}
							<StandardLayout
								path={route.path}
								component={route.component}
								requiresWebRTC={route.requiresWebRTC}
							/>
							<Redirect
								from={route.path.toLowerCase()}
								to={route.path}
								key={route.path.toLowerCase()}
							/>
							<Redirect
								from={route.path.toUpperCase()}
								to={route.path}
								key={route.path.toUpperCase()}
							/>
						</Fragment>
					))}
					<StandardLayout component={NotFound} default />
				</Router>
			</Provider>
		</React.StrictMode>
	);
};

export default App;
