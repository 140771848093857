/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Decentralized route handling
 * @author Erv Walter
 * @module Epic.VideoApp.App.Routes
 */

import { RouteComponentProps } from "@reach/router";
import { ComponentType } from "react";

const registeredRoutes: IRouteEntry[] = [];
const routePrefixRegex = new RegExp(/\/telehealth\/evc-(.*)\//gim);
let routePathPrefix: string | null = null;

export function getRegisteredRoutes(): IRouteEntry[] {
	return registeredRoutes;
}

export interface IRouteEntry {
	path: string;
	component: ComponentType<RouteComponentProps>;
	redirectPaths?: string[];
	requiresWebRTC?: boolean;
}

export interface IAddRouteFunction {
	(
		path: string,
		component: ComponentType<RouteComponentProps>,
		redirectPaths?: string[],
		requiresWebRTC?: boolean,
	): void;
}

/**
 * Routes are configured each time the app is loaded, so for efficiency we'll only create them
 * if we find the matching RegExp at initial load
 * @param path path to allow/display in browser
 * @param component component to load
 * @param redirectPaths array of paths to redirect to path
 * @param requiresWebRTC whether this component requires a WebRTC compat check in order to load
 */
export const addRoute: IAddRouteFunction = (path, component, redirectPaths, requiresWebRTC) => {
	const prefix = getRoutePathPrefix();

	if (prefix !== "/" && globalThis.location.pathname.startsWith(prefix)) {
		// Convert endpoint path to endpoint URL (by prepending the current path prefix)
		path = getEndpointUrl(path);

		// No need to process further if there is not a distinct new endpointUrl
		if (registeredRoutes.some((route) => route.path === path)) {
			return;
		}

		// update redirectPaths as well, i.e. for "/telehealth/evc-1999/VideoCall" update the redirects for "/telehealth/evc-1999/Home" as well
		if (redirectPaths && redirectPaths.length > 0) {
			redirectPaths.forEach((element, index) => {
				redirectPaths[index] = getEndpointUrl(element);
			});
		}
	}

	registeredRoutes.push({
		path,
		component,
		redirectPaths,
		requiresWebRTC,
	});
};

/**
 * Gets the path prefix for endpoint routes.
 * @returns the path prefix for endpoint routes.
 */
function getRoutePathPrefix(): string {
	if (routePathPrefix === null) {
		const regExResult = routePrefixRegex.exec(globalThis.location.pathname);
		routePathPrefix = !!regExResult && regExResult[0] ? regExResult[0] : "/";
		if (routePathPrefix && routePathPrefix !== "/") {
			// Make sure that the route path prefix starts with /, but does not end with /.
			if (!routePathPrefix.startsWith("/")) {
				routePathPrefix = "/" + routePathPrefix;
			}
			if (routePathPrefix.endsWith("/")) {
				routePathPrefix = routePathPrefix.slice(0, routePathPrefix.length - 1);
			}
			routePathPrefix = routePathPrefix.toLowerCase();
		}
	}

	return routePathPrefix;
}

/**
 * Gets the URL for a given endpoint path. Prepends the current path prefix when the EVC app is deployed to one or more sub-folders.
 * @param endpoint the endpoint path (e.g. "/api/Auth/VideoCall") to get the URL for.
 * @returns the URL for the given endpoint path.
 */
export function getEndpointUrl(endpoint: string): string {
	if (!endpoint) {
		return endpoint;
	}

	//If we have a server URL already specified, the path prefix should be baked into it already
	if (window.ServerUrl && window.ServerUrl !== "/") {
		return endpoint;
	}
	// No need to modify the endpoint if there is no path prefix or the endpoint already starts with the path prefix
	const pathPrefix = getRoutePathPrefix();
	if (
		!pathPrefix ||
		pathPrefix === "/" ||
		endpoint.toLowerCase().startsWith(pathPrefix) ||
		!window.location.pathname.toLowerCase().includes(pathPrefix)
	) {
		return endpoint;
	}

	// Prepend the path prefix to the endpoint.
	const pathSep = endpoint.startsWith("/") ? "" : "/";
	return pathPrefix + pathSep + endpoint;
}
