/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Renders the appropriate header content within a section of the participant list
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.ParticipantList.ParticipantSectionHeader
 */

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import React, { FC } from "react";
import styles from "./ParticipantList.module.scss";

interface IProps {
	headerText: string;
}

/**
 * The ParticipantSectionHeader component
 */
const ParticipantSectionHeader: FC<IProps> = (props) => {
	const { headerText, children } = props;

	return (
		<div className={styles["rowHeader"]}>
			<h2 tabIndex={0} className={styles["nameWrapper"]}>
				{headerText}
			</h2>
			{children}
		</div>
	);
};

ParticipantSectionHeader.displayName = "ParticipantSectionHeader";

export default ParticipantSectionHeader;
