/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hovering video preview component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Utilities.VideoPreview
 */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { FC, useCallback, useState } from "react";
import { useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import GrowShrinkArrow from "~/icons/growShrinkArrow";
import { useUserState } from "~/state";
import { resolveClassName } from "~/utils/className";
import { stringFormat } from "~/utils/strings";
import VideoStream from "~/web-core/components/VideoStream";
import { IStream } from "~/web-core/interfaces";
import DisabledCameraIndicator from "../Participants/Video/DisabledCameraIndicator";
import KeyboardShortcut from "../Utilities/KeyboardShortcut";
import styles from "./VideoPreview.module.scss";

/**
 * Props for the VideoPreview component
 */
interface IProps {
	/** The video track to render in the preview */
	stream: IStream | null;

	/** Whether or not the video track belongs to the local participant */
	isLocal?: boolean;

	/** Whether or not the video preview should be hidden */
	hidden?: boolean;

	/** Whether or not the size of the preview can be toggled */
	canToggleSize?: boolean;

	/** Action to take when the preview is clicked */
	onClick?: () => void;

	/** Display name of the participant that this video preview belongs to (if any) */
	participantDisplayName?: string;
}

/**
 * The VideoPreview component
 * @param props The props ;)
 */
const VideoPreview: FC<IProps> = (props) => {
	const { stream, isLocal, canToggleSize, hidden, onClick, participantDisplayName, children } = props;

	/**	with non-null but disabled tracks possible, use isEnabled as part of DisabledCamera logic */
	const [minimized, setMinimized] = useState<boolean>(false);
	const cameraLocked = useUserState((selectors) => selectors.getCameraLock(), []);

	const localLocked = isLocal && cameraLocked;

	const onTogglePreviewSize = useCallback((event?: React.MouseEvent<HTMLButtonElement>) => {
		setMinimized((prevMinimized) => !prevMinimized);

		// stop propagation to avoid also triggering the onClick
		event?.stopPropagation();
	}, []);

	const wrapperClassName = resolveClassName(styles, {
		videoPreview: true,
		minimized,
		noVideo: !stream,
		hidden,
	});

	const strings = useStrings("VideoPreview", Object.values(TokenNames));

	const shrinkVideoKeyboardShortcut = ["alt", "z"];
	const titleText = getShrinkButtonTitle(strings, minimized);
	const videoTitleFormatted = useGetFormattedHotkeyString(titleText, shrinkVideoKeyboardShortcut);
	const ariaLabel = useGetFormattedHotkeyString(
		getShrinkButtonAriaLabel(strings, isLocal, participantDisplayName),
		shrinkVideoKeyboardShortcut,
	);

	return (
		<div className={wrapperClassName} onClick={onClick}>
			{canToggleSize && (
				<>
					<KeyboardShortcut
						keyCombination={shrinkVideoKeyboardShortcut}
						callback={onTogglePreviewSize}
					/>
					<button
						aria-label={ariaLabel}
						aria-pressed={minimized}
						className={styles["togglePreviewSizeButton"]}
						onClick={onTogglePreviewSize}
					>
						<div
							className={styles["toggleButtonInner"]}
							tabIndex={-1}
							title={videoTitleFormatted}
							aria-hidden
						>
							<GrowShrinkArrow height={15} width={15} aria-hidden />
						</div>
					</button>
				</>
			)}
			{stream ? (
				<VideoStream stream={stream} isLocal={isLocal} videoType="camera" hideLoading />
			) : (
				<div className={styles["videoPlaceholder"]}>
					{!minimized && <DisabledCameraIndicator defaultSize={75} locked={localLocked} />}
				</div>
			)}
			{!minimized && children}
		</div>
	);
};

enum TokenNames {
	buttonTitle = "ButtonTitleShrinkNoHotkey",
	buttonTitleGrow = "ButtonTitleGrowNoHotkey",
	ariaLabel = "AriaLabel",
	ariaLabelWithName = "AriaLabelWithName",
	ariaLabelOthers = "AriaLabelOthers",
}

VideoPreview.displayName = "VideoPreview";

export default VideoPreview;

/**
 * Get title for the grow/shrink arrow button.
 * @param strings Translated strings for LocalVideoPreview component
 * @param minimized True if the preview is shrunk and clicking the button will grow the preview.
 * @returns The title to use for the grow/shrink arrow button
 */
function getShrinkButtonTitle(strings: Record<string, string>, minimized: boolean): string {
	return minimized ? strings[TokenNames.buttonTitleGrow] : strings[TokenNames.buttonTitle];
}

/**
 * Get aria-label for the grow/shrink arrow button.
 * @param strings Translated strings for LocalVideoPreview component
 * @param isLocal Is it a local participant?
 * @param displayName Display name of the participant
 * @returns The aria-label to use for the grow/shrink arrow button
 */
function getShrinkButtonAriaLabel(
	strings: Record<string, string>,
	isLocal?: boolean,
	displayName?: string,
): string {
	if (isLocal) {
		return strings[TokenNames.ariaLabel];
	}

	return displayName
		? stringFormat(strings[TokenNames.ariaLabelWithName], displayName)
		: strings[TokenNames.ariaLabelOthers];
}
