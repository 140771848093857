/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file types used for language and locale
 * @author Hanita Haller
 * @module Epic.VideoApp.Types.Locale
 */

export interface ILocale {
	/** The locale code for the locale (i.e. en-us) */
	localeCode: string;
	/** The name of the language to display to users */
	displayName: string;
	/** The string to use for the aria label */
	ariaLabel?: string;
}

export type Direction = "ltr" | "rtl";

export interface IDomElements {
	htmlEl: HTMLHtmlElement | null;
}

export interface ILanguageInfo {
	displayName: string;
	ariaLabel?: string;
	isRtl?: boolean;
	includesPostAuth?: boolean;
	isWin1252Incompatible?: boolean;
}

export interface ITranslationInfo extends ILanguageInfo {
	isRtl: boolean;
	translation: Record<string, string>;
}

export const ENGLISH_US_LOCALE = "en-us";
export const ENABLED_LOCALES: Record<string, ILanguageInfo> = {
	[ENGLISH_US_LOCALE]: {
		displayName: "English",
		includesPostAuth: true,
	},
	"ar-ae": {
		displayName: "العربية",
		ariaLabel: "Arabic",
		isRtl: true,
		isWin1252Incompatible: true,
	},
	"da-dk": {
		displayName: "Dansk",
	},
	"de-de": {
		displayName: "Deutsch",
	},
	"es-us": {
		displayName: "Español",
	},
	"fi-fi": {
		displayName: "Suomi",
	},
	"fr-fr": {
		displayName: "Français",
	},
	"ht-us": {
		displayName: "Kreyòl Ayisyen",
	},
	"pt-br": {
		displayName: "Português",
	},
	"pl-pl": {
		displayName: "Polski",
	},
	"nb-no": {
		displayName: "Norsk",
	},
	"nl-nl": {
		displayName: "Nederlands",
	},
	"ru-us": {
		displayName: "Русский",
		ariaLabel: "Russian",
		isWin1252Incompatible: true,
	},
	"sv-se": {
		displayName: "Svenska",
	},
	"tl-us": {
		displayName: "Tagalog",
	},
	"vi-us": {
		displayName: "Tiếng Việt",
	},
	"zh-ushans": {
		displayName: "简体中文",
		ariaLabel: "Simplified Chinese",
		isWin1252Incompatible: true,
	},
	"zh-ushant": {
		displayName: "繁體中文",
		ariaLabel: "Traditional Chinese",
		isWin1252Incompatible: true,
	},
	"zz-epic": {
		displayName: "Gibberish",
	},
	"zz-epicid": {
		displayName: "String IDs",
	},
};
